'use client';

import { Crisp } from 'crisp-sdk-web';
import { useSession } from 'next-auth/react';
import { usePathname } from 'next/navigation';
import { useEffect } from 'react';

import config from '@/config';

export default function Support() {
	const pathname = usePathname();
	const { data } = useSession();

	useEffect(() => {
		if (config?.crisp?.id) {
			// Set up Crisp
			Crisp.configure(config.crisp.id);

			// (Optional) If onlyShowOnRoutes array is not empty in config.js file, Crisp will be hidden on the routes in the array.
			// Use <AppButtonSupport> instead to show it (user clicks on the button to show Crisp—it cleans the UI)
			if (config.crisp.onlyShowOnRoutes && !config.crisp.onlyShowOnRoutes?.includes(pathname)) {
				Crisp.chat.hide();
				Crisp.chat.onChatClosed(() => {
					Crisp.chat.hide();
				});
			}
		}
	}, [pathname]);

	// Add User Unique ID to Crisp to easily identify users when reaching support (optional)
	useEffect(() => {
		if (data?.user && config?.crisp?.id) {
			Crisp.session.setData({ userId: data.user?.id, email: data?.user?.email });
		}
	}, [data]);

	return null;
}
