import(/* webpackMode: "eager" */ "/vercel/path0/apps/delphi/src/app/delphi.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/delphi/src/components/custom-font.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/delphi/src/features/auth/providers/auth-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/delphi/src/features/support/components/support.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/.pnpm/@vercel+analytics@1.3.1_next@14.2.13_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1__react@18.3.1/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/.pnpm/@vercel+speed-insights@1.0.12_next@14.2.13_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18_6wf2c2hasfh6evy7vlcf4agvgq/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["ViewTransitions"] */ "/vercel/path0/node_modules/.pnpm/next-view-transitions@0.3.2_next@14.2.13_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3_w4vs67pnh77jhylm3bipyya4eu/node_modules/next-view-transitions/dist/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.13_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.13_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"src/utils/font/font.ts\",\"import\":\"\",\"arguments\":[{\"variable\":\"--delphi-font-heading\",\"display\":\"swap\",\"src\":[{\"path\":\"./../../../public/fonts/new-spirit/new-spirit-300.woff2\",\"weight\":\"300\"},{\"path\":\"./../../../public/fonts/new-spirit/new-spirit-300-italic.woff2\",\"weight\":\"300\",\"style\":\"italic\"},{\"path\":\"./../../../public/fonts/new-spirit/new-spirit-400.woff2\",\"weight\":\"400\"},{\"path\":\"./../../../public/fonts/new-spirit/new-spirit-400-italic.woff2\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"./../../../public/fonts/new-spirit/new-spirit-500.woff2\",\"weight\":\"500\"},{\"path\":\"./../../../public/fonts/new-spirit/new-spirit-500-italic.woff2\",\"weight\":\"500\",\"style\":\"italic\"},{\"path\":\"./../../../public/fonts/new-spirit/new-spirit-600.woff2\",\"weight\":\"600\"},{\"path\":\"./../../../public/fonts/new-spirit/new-spirit-600-italic.woff2\",\"weight\":\"600\",\"style\":\"italic\"},{\"path\":\"./../../../public/fonts/new-spirit/new-spirit-700.woff2\",\"weight\":\"700\"},{\"path\":\"./../../../public/fonts/new-spirit/new-spirit-700-italic.woff2\",\"weight\":\"700\",\"style\":\"italic\"}]}],\"variableName\":\"headingFont\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.13_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"src/utils/font/font.ts\",\"import\":\"\",\"arguments\":[{\"variable\":\"--delphi-font-body\",\"display\":\"swap\",\"src\":[{\"path\":\"./../../../public/fonts/general-sans/general-sans-variable.woff2\"},{\"path\":\"./../../../public/fonts/general-sans/general-sans-variable-italic.woff2\",\"style\":\"italic\"}]}],\"variableName\":\"bodyFont\"}");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/.pnpm/nextjs-toploader@1.6.12_next@14.2.13_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1___yqaum6rz3ngovdvjkqpxvs75ki/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/node_modules/.pnpm/sonner@1.5.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/sonner/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["themeDetectionScript"] */ "/vercel/path0/packages/ui/src/components/layout/theme-controller/theme-controller.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TooltipProvider"] */ "/vercel/path0/packages/ui/src/components/ui/tooltip.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/vercel/path0/packages/ui/src/providers/theme/theme-provider.tsx");
