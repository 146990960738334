'use client';

import { bodyFont, headingFont } from '@/utils/font/font';

/**
 * Next.js font loading works all fine, except when elements are injected
 * into the DOM outside the main element (e.g. Cookiebot modal). Hence,
 * set font var manually to root.
 *
 * @see https://nextjs.org/docs/basic-features/font-optimization
 */
export default function CustomFont() {
	return (
		<style jsx global>{`
			:root {
				--delphi-font-heading: ${headingFont.style.fontFamily};
				--delphi-font-body: ${bodyFont.style.fontFamily};
			}
		`}</style>
	);
}
