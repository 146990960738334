import { ConfigProps } from '@customTypes/global/config.type';

import twConfig from '../tailwind.config';

const config = {
	// REQUIRED
	appName: 'Delphi',
	// REQUIRED: a short description of your app for SEO tags (can be overwritten)
	appDescription:
		'Altijd genoeg inspiratie voor nieuwe content, weten hoe je scroll-stoppende en meeslepende teksten schrijft en maandelijks klanten binnenhalen dankzij je teksten!',
	// REQUIRED (no https://, not trialing slash at the end, just the naked domain)
	domainName: 'coachdelphi.ai',
	crisp: {
		// Crisp website ID. IF YOU DON'T USE CRISP: just remove this => Then add a support email in this config file (mailgun.supportEmail) otherwise customer support won't work.
		id: process.env.NEXT_PUBLIC_CRISP_WEBSITE_ID,
		// Hide Crisp by default, except on route "/". Crisp is toggled with <ButtonSupport/>. If you want to show Crisp on every routes, just remove this below
		onlyShowOnRoutes: ['/'],
	},
	stripe: {
		// Create multiple plans in your Stripe dashboard, then add them here. You can add as many plans as you want, just make sure to add the priceId
		plans: [
			{
				// REQUIRED — we use this to find the plan in the webhook (for instance if you want to update the user's credits based on the plan)
				priceId:
					process.env.NODE_ENV === 'development' ? 'price_1PdwAiEsYjFXHqWnAtk1wasg' : 'price_1PcXZ0EsYjFXHqWn78rAeTU4',
				// This plan will look different on the pricing page, it will be highlighted. You can only have one plan with isFeatured: true
				isFeatured: true,
				//  REQUIRED - Name of the plan, displayed on the pricing page
				name: 'Pilot',
				// A friendly description of the plan, displayed on the pricing page. Tip: explain why this plan and not others
				description: 'Exclusieve toegang tot de pilot van Delphi.',
				// The price you want to display, the one user will be charged on Stripe.
				price: 9.99,
				// If you have an anchor price (i.e. $29) that you want to display crossed out, put it here. Otherwise, leave it empty
				// priceAnchor: ,
				features: [
					{
						name: 'Diepte-interview',
					},
					{ name: 'Content strategie' },
					{ name: 'Tekstcorrectie' },
					{ name: 'Sparringspartner' },
				],
			},
		],
	},
	aws: {
		// If you use AWS S3/Cloudfront, put values in here
		bucket: 'bucket-name',
		bucketUrl: `https://bucket-name.s3.amazonaws.com/`,
		cdn: 'https://cdn-id.cloudfront.net/',
	},
	mailgun: {
		// subdomain to use when sending emails, if you don't have a subdomain, just remove it. Highly recommended to have one (i.e. mg.yourdomain.com or mail.yourdomain.com)
		subdomain: 'mail',
		// REQUIRED — Email 'From' field to be used when sending magic login links
		fromNoReply: `Delphi <noreply@delphi.coach>`,
		// REQUIRED — Email 'From' field to be used when sending other emails, like abandoned carts, updates etc..
		fromAdmin: `Karlijn van Delphi <karlijn@delphi.coach>`,
		// Email shown to customer if need support. Leave empty if not needed => if empty, set up Crisp above, otherwise you won't be able to offer customer support."
		supportEmail: 'sander@delphi.coach',
		// When someone replies to supportEmail sent by the app, forward it to the email below (otherwise it's lost). If you set supportEmail to empty, this will be ignored.
		forwardRepliesTo: 'sander@sdkode.dev',
	},
	colors: {
		// REQUIRED — The DaisyUI theme to use (added to the main layout.js). Leave blank for default (light & dark mode). If you any other theme than light/dark, you need to add it in config.tailwind.js in daisyui.themes.
		theme: '',
		// REQUIRED — This color will be reflected on the whole app outside of the document (loading bar, Chrome tabs, etc..). By default it takes the primary color from your DaisyUI theme (make sure to update your the theme name after "data-theme=")
		// OR you can just do this to use a custom color: main: "#f37055". HEX only.
		main: twConfig.daisyui.themes[0].light.primary,
		light: twConfig.daisyui.themes[0].light.primary,
		dark: twConfig.daisyui.themes[1].dark.primary,
	},
	auth: {
		// REQUIRED — the path to log in users. It's use to protect private routes (like /dashboard). It's used in apiClient (/libs/api.js) upon 401 errors from our API
		loginUrl: '/login',
		// REQUIRED — the path you want to redirect users after successfull login (i.e. /dashboard, /private). This is normally a private page for users to manage their accounts. It's used in apiClient (/libs/api.js) upon 401 errors from our API & in ButtonSignin.js
		callbackUrl: '/portal',
	},
} as ConfigProps;

export default config;
