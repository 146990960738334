import type { Config } from 'tailwindcss';
import defaultTheme from 'tailwindcss/defaultTheme';

import sharedConfig, { themes } from '@repo/ui/tailwind.config';

const config: Pick<Config, 'presets' | 'darkMode' | 'theme' | 'daisyui'> = {
	presets: [sharedConfig],
	darkMode: ['class', '[data-theme="dark"]'],
	theme: {
		extend: {
			backgroundImage: {
				instagram: 'linear-gradient(45deg, #833ab4, #fd1d1d, #fcb045)',
				linkedin: 'linear-gradient(45deg, #0077b5, #00a0dc)',
				x: 'linear-gradient(45deg, #000000, #000000)',
				facebook: 'linear-gradient(45deg, #1877F2, #3b5998)',
				threads: 'linear-gradient(45deg, #000000, #833ab4, #fd1d1d, #fcb045)',
			},
			fontFamily: {
				heading: ['var(--delphi-font-heading)', ...defaultTheme.fontFamily.sans],
				body: ['var(--delphi-font-body)', ...defaultTheme.fontFamily.sans],
			},
		},
	},
	daisyui: {
		themes: [
			{
				light: {
					...themes['light'],
					primary: '#0B2AA8',
					secondary: '#284FF1',
					'primary-content': '#EEE3DD',
					'base-100': '#E0C5B8',
				},
			},
			{
				dark: {
					...themes['dark'],
					primary: '#D4A17D',
					secondary: '#284FF1',
					'primary-content': '#031526',
					'base-100': '#062C51',
				},
			},
		],
		darkTheme: 'dark',
	},
};

export default config;
